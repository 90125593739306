import React, { useEffect, useState } from "react"
import InvestorPopup from "../components/common/investorPopup"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { useStaticQuery, graphql } from "gatsby"
import "../css/common.css"

const PrivacyPolicy = () => {
  const PrivacyPolicySection = useStaticQuery(graphql`
    query PrivacyPolicy {
      allWpPage(filter: { id: { eq: "cG9zdDozMjM=" } }) {
        edges {
          node {
            Privacy_policy {
              privacyTitle
            }
            content
          }
        }
      }
    }
  `)
  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])

  console.log(PrivacyPolicySection)
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container pt-20 lg:pt-24 px-3 md:px-6">
        <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
          <h2 className="text-4xl lg:text-6xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-12 ">
            {
              PrivacyPolicySection.allWpPage.edges[0].node.Privacy_policy
                .privacyTitle
            }
          </h2>
        </div>
        <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10">
          <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
          <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
        </div>
        <div className="privacy-policy w-full lg:w-10/12 xl:w-2/3 mx-auto mt-10">
          <div
            dangerouslySetInnerHTML={{
              __html: PrivacyPolicySection.allWpPage.edges[0].node.content,
            }}
            className="leading-normal text-sm"
          ></div>
        </div>
        {investPopup ? (
          <InvestorPopup setInvestPopup={setInvestPopup} />
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
